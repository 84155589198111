* {
    box-sizing: border-box;
}

html,
body {
    min-height: 100vh;
    max-width: 100vw;
    width: 100%;
    margin: 0;
    padding: 0;
    background-color: rgba(8, 27, 34, 1);
}

body {
    font-family: "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", Osaka,
        "メイリオ", Meiryo, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
        monospace;
}

.ss {
    font-size: 1px;
    height: 1px;
    width: 1px;
}
